.no-access-body{
    color: red;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    height: 100vh;
    text-decoration: solid;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font: bold;
}
